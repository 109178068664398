import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Main } from '../components/Main';
import { Content } from '../components/Content';
import { Lightbox } from '../components/Lightbox';

const title = 'Výroba kuchýň a nábytku na mieru';

export default () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(graphql`
    {
      allFile(sort: { fields: name, order: DESC }, filter: { dir: { regex: "/vyroba/" } }) {
        nodes {
          ...fluidImage
        }
      }
    }
  `);

  return (
    <Layout>
      <Meta title={title} />
      <Main>
        <h1>{title}</h1>
        <Content>
          <div className="flex flex-wrap -m-2">
            {images.map((image, i) => (
              <Lightbox image={image} key={i} className="w-1/2 p-2" />
            ))}
          </div>
        </Content>
      </Main>
    </Layout>
  );
};
